import './SliderAFromDirectory.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Button } from '../Button';
import { SliderAContentType, SliderASlideContentType } from '../contentTypes';
import { Icon } from '../Icon';

interface SliderAFromDirectoryProps {
  directoryCode: string;
}

export const SliderAFromDirectory = ({ directoryCode }: SliderAFromDirectoryProps) => {
  const directory = b2x.useDirectory<SliderAContentType, SliderASlideContentType>(directoryCode, {
    populate: { additionalContents: true, content: true },
  });

  const { shippingCountry } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  return (
    <b2x.EqualHeight>
      <b2x.SwiperContext>
        {({ navigationNextElRef, navigationPrevElRef, paginationElRef }) => (
          <div className="slider-a position-relative">
            <b2x.SwiperFromContent
              {...directory?.content?.body.options}
              centeredSlides
              className="slider"
              navigation={{ custom: true }}
              pagination={{ clickable: true, custom: true }}
              parallax
              slides={directory?.additionalContents?.map((additionalContent) => {
                const contentSection = b2x.getContentSectionByShippingCountry(
                  [additionalContent.body],
                  shippingCountry
                );
                if (contentSection) {
                  return (
                    <div key={additionalContent.id} style={{ position: 'relative' }}>
                      <b2x.ConditionalWrapper
                        condition={contentSection.cta !== undefined}
                        wrapper={
                          <b2x.router.Link
                            to={
                              contentSection.cta?.to?.href
                                ? contentSection.cta.to.href
                                : contentSection.cta?.to?.code && getPagePath(contentSection.cta.to.code)
                            }
                          />
                        }
                      >
                        <b2x.EqualHeightElement name="asset">
                          <div className="text-center">
                            <b2x.AssetV2 {...contentSection.asset} fluid />
                          </div>
                        </b2x.EqualHeightElement>

                        <div className="message">
                          <b2x.Container>
                            <div
                              className={classnames(
                                'd-lg-flex',
                                'pt-4 pt-lg-0',
                                'justify-content-start',
                                {
                                  'justify-content-center': contentSection.copy?.alignment === 'center',
                                  'text-center': contentSection.copy?.alignment === 'center',
                                },
                                {
                                  'justify-content-end': contentSection.copy?.alignment === 'right',
                                  'text-end': contentSection.copy?.alignment === 'right',
                                }
                              )}
                            >
                              <div className="col-lg-7 col-12">
                                {contentSection.copy?.title && (
                                  <h4 className={classnames('title display-4')}>
                                    <span
                                      className={`d-lg-inline d-none bg-${contentSection.copy.titleBackgroundColor} text-${contentSection.copy.titleColor} lh-sm`}
                                    >
                                      {b2x.formatHtml(contentSection.copy.title)}
                                    </span>
                                    <span className={`d-lg-none d-block text-${contentSection.copy.titleColor}`}>
                                      {b2x.formatHtml(contentSection.copy.title)}
                                    </span>
                                  </h4>
                                )}
                                {contentSection.copy?.content && (
                                  <h5 className={classnames('h2')}>
                                    <span
                                      className={`d-lg-inline d-none bg-${contentSection.copy.contentBackgroundColor} text-${contentSection.copy.contentColor} lh-sm`}
                                    >
                                      {b2x.formatHtml(contentSection.copy.content)}
                                    </span>
                                    <span className={`d-lg-none d-block text-${contentSection.copy.contentColor}`}>
                                      {b2x.formatHtml(contentSection.copy.content)}
                                    </span>
                                  </h5>
                                )}
                                {contentSection.ctaSection?.firstCta?.label && (
                                  <div className="mt-4 d-md-inline-block">
                                    <b2x.DeprecatedCta
                                      className={classnames(
                                        'btn px-5',
                                        `btn-${contentSection.ctaSection.firstCta.variant}`
                                      )}
                                      cta={contentSection.ctaSection.firstCta}
                                    >
                                      {b2x.formatHtml(contentSection.ctaSection.firstCta.label)}
                                    </b2x.DeprecatedCta>
                                  </div>
                                )}
                                {contentSection.ctaSection?.secondCta?.label && (
                                  <div className="d-md-inline-block mt-3 mt-md-0 mx-md-4 mt-4">
                                    <b2x.DeprecatedCta
                                      className={classnames(
                                        'btn px-5',
                                        `btn-${contentSection.ctaSection.secondCta.variant}`
                                      )}
                                      cta={contentSection.ctaSection.secondCta}
                                    >
                                      {b2x.formatHtml(contentSection.ctaSection.secondCta.label)}
                                    </b2x.DeprecatedCta>
                                  </div>
                                )}
                                <div className="d-lg-none mb-4"></div>
                                {/* {slide.cta && slide.cta.label && (
                                <div className="mt-4">
                                  <b2x.DeprecatedCta
                                    className={classnames('btn', `btn-${slide.cta.variant}`)}
                                    cta={slide.cta}
                                  >
                                    {b2x.formatHtml(slide.cta.label)}
                                  </b2x.DeprecatedCta>
                                </div>
                              )}
                              */}
                                {contentSection.copy?.disclaimer && (
                                  <p className="disclaimer mt-3 mt-lg-2 small">
                                    <span
                                      className={`d-lg-inline d-none text-${contentSection.copy.disclaimerColor} lh-sm`}
                                    >
                                      {b2x.formatHtml(contentSection.copy.disclaimer)}
                                    </span>
                                    <span className="d-lg-none d-block">
                                      {b2x.formatHtml(contentSection.copy.disclaimer)}
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div>
                          </b2x.Container>
                        </div>
                      </b2x.ConditionalWrapper>
                    </div>
                  );
                } else {
                  return undefined;
                }
              })}
            >
              <b2x.Div
                className="position-absolute top-0 start-0 bottom-0 end-0"
                style={{ pointerEvents: 'none', zIndex: 2 }}
              >
                <b2x.Div className="h-100 position-relative d-none d-xl-block slider-navigation">
                  <b2x.Div className="position-relative h-100">
                    <b2x.Div
                      className={classnames('position-absolute top-50 start-0 text-start')}
                      style={{ pointerEvents: 'all', transform: 'translateY(-50%) translateX(0)' }}
                    >
                      <Button
                        className="slider-button swiper-button-prev"
                        innerRef={navigationPrevElRef}
                        variant="blank"
                      >
                        <Icon className="text-white" name="large-arrow-left" size={30} />
                      </Button>
                    </b2x.Div>
                    <b2x.Div
                      className={classnames('position-absolute top-50 end-0 text-start')}
                      style={{ pointerEvents: 'all', transform: 'translateY(-50%) translateX(0)' }}
                    >
                      <Button
                        className="slider-button swiper-button-next"
                        innerRef={navigationNextElRef}
                        variant="blank"
                      >
                        <Icon className="text-white" name="large-arrow-right" size={30} />
                      </Button>
                    </b2x.Div>
                  </b2x.Div>
                </b2x.Div>
              </b2x.Div>

              <b2x.Div className="slider-pagination position-relative" display={{ xl: 'none', xs: 'block' }}>
                <b2x.Div
                  alignItems="center"
                  className="pagination-wrapper bottom-0"
                  display="flex"
                  innerRef={paginationElRef}
                  justifyContent="center"
                  style={{ pointerEvents: 'all' }}
                />
              </b2x.Div>
            </b2x.SwiperFromContent>
          </div>
        )}
      </b2x.SwiperContext>
    </b2x.EqualHeight>
  );
};
